<template>
    <div class="login-container">
        <el-form
            autoComplete="on"
            :model="forgotPassForm"
            :rules="loginRules"
            ref="forgotPassForm"
            label-position="left"
            label-width="0px"
            class="card-box login-form"
        >
            <div class="login-logo title-no-border" :class="{ 'is-loading': isLoading }">
                <img :src="logoPath" alt="Cel.ro Marketplace" />
                <div class="logoText-big"><span class="primary">market</span>place</div>
                <div class="logoText-small">marketplace.cel.ro</div>
            </div>
            <template v-if="isLoading">
                <div class="lds-dual-ring"></div>
            </template>
            <template v-else-if="hasToken && reactiveTokenCheck">
                <div class="requestSent">{{ $t('login.pass-reset') }}</div>
            </template>
            <template v-else-if="!requestSent">
                <div class="generalError" v-if="generalError.length > 0">{{ generalError }}</div>
                <el-form-item prop="username">
                    <el-input
                        name="username"
                        type="text"
                        @keyup.enter.native="handleForgot"
                        v-model="forgotPassForm.username"
                        autoComplete="on"
                        :placeholder="$t('login.useroremail')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        style="width:100%;"
                        :disabled="requestSent"
                        :loading="loading"
                        @click.native.prevent="handleForgot"
                    >
                        {{ $t('login.reset-send') }}
                    </el-button>
                </el-form-item>
            </template>
            <template v-else>
                <div class="requestSent">{{ $t('login.reset-sent') }}</div>
            </template>
            <div class="forgotPassword">
                <router-link to="/login">
                    {{ $t('login.reset-login') }}
                </router-link>
            </div>
            <div class="noAccountLogin">
                <router-link to="/">
                    {{ $t('login.reset-signup') }}
                </router-link>
            </div>
        </el-form>
    </div>
</template>

<script>
import logoPath from '@/assets/images/bulina_109.png';
import { resetPassword, requestPasswordReset } from '@/api/login';

export default {
    name: 'forgotPassword',
    props: ['token'],
    metaInfo() {
        return {
            meta: [{ name: 'robots', content: 'noindex' }]
        };
    },
    data() {
        const validateUsername = (rule, value, callback) => {
            if (value.length < 1) {
                callback(new Error(this.$t('login.no-text')));
            } else {
                callback();
            }
        };
        return {
            forgotPassForm: {
                username: ''
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: validateUsername
                    }
                ]
            },
            loading: false,
            logoPath,
            requestSent: false,
            tokenisValid: false,
            generalError: '',
            isLoading: true
        };
    },
    methods: {
        handleForgot() {
            this.$refs.forgotPassForm.validate((valid) => {
                if (valid) {
                    this.isLoading = true;
                    requestPasswordReset(this.forgotPassForm)
                        .then((res) => {
                            if (typeof res.message !== 'undefined' && typeof res.message.success !== 'undefined') {
                                this.requestSent = true;
                            }
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'requestPasswordReset',
                                    params: this.forgotPassForm
                                }
                            });
                        })
                        .finally(() => (this.isLoading = false));
                } else {
                    return false;
                }
            });
        },
        async resetPassword() {
            this.isLoading = true;
            return resetPassword({
                token: this.reactiveToken
            })
                .then((res) => {
                    if (
                        typeof res !== 'undefined' &&
                        typeof res.message !== 'undefined' &&
                        typeof res.message.reset !== 'undefined'
                    ) {
                        this.tokenisValid = true;
                    } else {
                        this.generalError = this.$t('login.token-expired');
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'resetPassword',
                            params: {
                                token: this.reactiveToken
                            }
                        }
                    });
                })
                .finally(() => (this.isLoading = false));
        }
    },
    computed: {
        reactiveToken() {
            return this.token;
        },
        hasToken() {
            return typeof this.token !== 'undefined';
        },
        reactiveTokenCheck() {
            return this.tokenisValid;
        }
    },
    async mounted() {
        if (this.hasToken) {
            await this.resetPassword();
        }
        this.isLoading = false;
        if (typeof window.notificationInterval !== 'undefined') window.clearInterval(window.notificationInterval);
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import './src/styles/mixin.scss';
@import './src/styles/modules/login.scss';
@import './src/styles/modules/forgotpassword.scss';
</style>
